// 返现活动
import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import commonModel from '../common'
import router from '@/router'

class Cashback{
    /**
     * 获取返现活动列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCashBack(page,limit,extra=null,fn){
        let param = { page,limit }
        commonModel.getDataList("getCashList",param).then(res=>fn(res))
    }

    /**
     * 获取返现活动详情
     * @param {number} id 
     * @param {Array} keys 
     * @param {function} fn 
     */
    getCashBackDetail(id,keys,fn){
        $post('getCashDetail',{id}).then(res=>{
            if( keys ){
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        })
    }

    addOrEditCashback(param){
		if( !tool.returnMessage(param.good_id,'请选择商品')) return
        if( param.cash_type != 1 && param.end_time <=0 ){
            tool.message("返现次数必须大于0","warning")
            return
        }	

        if( param.money <=0 ){
            tool.message("返现金额必须大于0")
            return
        }
        if( !param.id ) delete param.id
        $post("createOrUpdateCash",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取返现日志
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCashBackLog(page,limit,extra,fn){
        let param = { page,limit}
        if( extra.id ) param.id =extra.id
        commonModel.getDataList("getCashPriceLog",param).then(res=>fn(res))
    }

    /**
     * 获取返现状态信息
     * @param {string} type  订单类型 1商城 2土地 3认养
     * @param {number} order_id 
     * @param {function} fn 
     */
    getCashBackOrderStatus(type,order_id,fn){
        $post("getOrderJonStatus",{type,order_id},false).then(res=>fn(res)).catch(res=>{
            fn(res)
        })
    }

    /**
     * 返现暂停或开始操作
     * @param {number} type  订单类型 1商城 2土地 3认养
     * @param {string} order_type 
     * @param {number} order_id 
     * @param {function} fn 
     */
    handleCashbackOrder(type,order_type,order_id,fn){
        tool.confirm('确认'+(type==1 ? '开始':'暂停') +'该订单的返现操作吗?').then(()=>{
            $post('createOrDelJob',{order_id,type,order_type},2).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }
}

const cashbackModel = new Cashback()
export default cashbackModel