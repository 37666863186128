<template>
	<div class="pay-result">
		<a-button type="link" @click="queryOrderPay">[查询订单支付状态]</a-button>
		<template v-if="info"> 
			<a-modal title="支付状态查询结果" v-model:visible="showDialog" :footer="null" @cancel="showDialog = false" width="400px">
				<div class="kd-pay-result" v-if="info && info.type == 1">
					<p>支付状态：{{info.data.return_code}}({{info.data.trade_state_desc}})</p>
					<p>支付金额：{{info.data.total_fee/100}}</p>
					<p>支付交易号：{{info.data.transaction_id}}</p>
					<p>支付订单号：{{info.data.out_trade_no}}</p>
				</div>
				<div class="kd-pay-result" v-if="info && info.type == 2">
					<p>支付状态：{{info.data.msg}}</p>
					<p>支付金额：{{info.data.total_amount}}</p>
					<p>支付交易号：{{info.data.trade_no}}</p>
					<p>支付订单号：{{info.data.out_trade_no}}</p>
				</div>
			</a-modal>
		</template>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import commonModel from '@/api/common'
import tool from '@/util/tool'
export default{
	name:"com-pay-result",
	props:{
		id:{
			type:[String,Number],
			default:0
		},
		type:{		//订单类型 type 3=认养订单
			type:Number,
			default:1
		}
	},
	setup(props){
		const state = reactive({
			showDialog:false,
			info:null,
		})

		function closeThis(){
			state.showDialog = false
		}

		function queryOrderPay(){
			commonModel.queryOrderPay(props.type,props.id,res=>{
				console.log('res',res);
				if( res ){
					if( res.errorCode == 0 ){
						state.info = res.data
					}else{
						tool.message(res.message,"warning")
						closeThis()
					}
				}else{
					tool.message("未找到相关订单信息！","warning")
					closeThis()
				}
			})
		}

		return{
			...toRefs(state),
			closeThis,
			queryOrderPay
		}
	}
}
</script>

<style lang="scss">
	.pay-result{
		display: inline-block;
	}
</style>
