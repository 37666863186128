<template>
    <div v-if="custom" style="cursor: pointer;" 
        v-clipboard:copy="value" 
        v-clipboard:success="copySuccess" 
        v-clipboard:error="copyError">
        <slot></slot>
    </div>
    <a-button 
        v-else
        :type="btnType"  
        v-clipboard:copy="value" 
        v-clipboard:success="copySuccess" 
        v-clipboard:error="copyError">复制
    </a-button>
</template>
<script>
import tool from '@/util/tool'
export default {
    name:'com-copy',
    props:{
        value:{
            type:String
        },
        btnType:{
            type:String,
            default:'link',
        },
        custom:{        //自定义文字、样式
            type:Boolean,
            default:false
        }
    },
    setup() {
        function copySuccess(){
            tool.message("复制成功")
        }
        
        function copyError(){
            tool.message("复制失败","error")
        }
        return{
            copySuccess,
            copyError
        }
    },
}
</script>