<template>
	<div class="com-set-remark">
		<a-button size="small" :type="btnType" @click="show = true">{{btnText}}</a-button>
		<a-modal title="设置备注" v-model:visible="show" @ok="saveRemark" @cancel="show = false">
		    <a-textarea v-model:value="value" placeholder="请填写备注" :rows="6" />
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs ,watch} from 'vue';
import { $post } from '@/api/http';
import tool from '@/util/tool';
export default{
	name:'com-set-remark',
	props:{
		btnText:{		//按钮文字
			type:String,
			default:"备注"
		},
		btnType:{		//按钮类型
			type:String,
			default:"default"
		},
		params:{		//必要参数信息
			type:[Object,Array],
		},
		postApi:{		//请求接口
			type:String,
		},
		remarkType:{	//操作类型 adopt-order 认养订单 goods-order 商城订单
			type:String,
		},
		remarkValue:{	//默认值
			type:String,
		}
	},
	setup(props,context){
		const state = reactive({
			show:false,
			value:props.remarkValue || ''
		})

		watch(() => props.remarkValue,(value) => {
                state.value  = value
            }
        )

		const saveRemark =()=>{
			let data = {
				...props.params,
				manager_remark:state.value
			}
			$post(props.postApi,data).then(res=>{
				tool.message("保存成功")
				state.show = false
				context.emit("success",true)
			})
		}

		return{
			...toRefs(state),
			saveRemark
		}
	}
}
</script>

<style>
	.com-set-remark{
		display: inline-block;
	}
</style>
