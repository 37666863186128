<template>
	<div class="com-logostics">
		<a-button :size="btnSize" :type="btnType" @click="getLogistics()">{{ btnText }}</a-button>
		<a-modal title="查看物流" v-model:visible="show" :footer="null" @cancel="show = false" width="700px">
			 <a-timeline reverse="true">
			    <a-timeline-item v-for="(item, index) in traces" :key="index">
					{{item.AcceptStation}}
					<span class="f12">{{ item.AcceptTime }}</span>
				</a-timeline-item>
			  </a-timeline>
			  <div v-if="traces.length ==0 ">{{reason}}</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import commonModel from '@/api/common'
export default{
	name:'com-logistics',
	props:{
		orderType:{
			type:Number  //1商城订单 4土地订单 3认养订单
		},
		pk:{
			type:Number	//订单id
		},
		btnSize:{
			type:String
		},
		btnType:{
			type:String
		},
		btnText:{
			type:String,
			default:"查看物流"
		}
	},
	setup(props){
		const state = reactive({
			show:false,
			traces: [],
			reason:'',
			isLoading:true
		})

		function getLogistics(){
			commonModel.getLogistics(props.pk,props.orderType,res=>{
				state.show = true
				if( res.Traces && res.Traces.length > 0 ){
					state.traces = res.Traces
				}else{
					state.reason = res.Reason
				}
			})
		}

		return{
			...toRefs(state),
			getLogistics
		}
	}
}
</script>

<style>
	.com-logostics{
		display: inline-block;
	}
	.logostics_container{
		width: 100%;
		height: 400px;
		overflow: hidden;
		overflow-y:auto
	}
</style>
