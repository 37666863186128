<template>
	<div class="kd-print-face-order">
		<a-button :size="btnSize" :type="btnType" @click="showPrint">{{btnText}}</a-button>
		<a-modal v-model:visible="show" title="打印电子面单" width="700px" @ok="printNow" @cancel="show=false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}" v-if="!template_face">
				<a-form-item label="打印方式">
					<a-radio-group v-model:value="print_type">
					    <a-radio :value="1" :disabled="orderId.length != 1">浏览器打印</a-radio>
					    <a-radio :value="2">Lodop插件打印</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="取件时间范围">
					<a-range-picker
						style="width: 400px"
						v-model:value="time"
						:showTime="true"
						format="YYYY-MM-DD HH:mm:ss"
					/>
				</a-form-item>
				<a-form-item label="快递公司">
					<a-select v-model:value="form.express_company">
						<a-select-option value="">请选择快递公司</a-select-option>
						<a-select-option :value="item.express_company" v-for="(item,index) in expressData" :key="index">
							{{item.express_company}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="重量">
					<a-input v-model:value="form.Weight" placeholder="重量" addon-after="kg"></a-input>
				</a-form-item>
				<a-form-item label="是否通知取件员">
					<a-radio-group v-model:value="form.IsNotice">
					    <a-radio :value="1">通知</a-radio>
					    <a-radio :value="0">不通知</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="支付方式">
					<a-radio-group v-model:value="form.PayType">
					    <a-radio :value="1">现付</a-radio>
					    <a-radio :value="2">到付</a-radio>
					    <a-radio :value="3">月结</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item label="选择打印机">
					<a-select v-model:value="print_index">
						<a-select-option value="">请选择打印机</a-select-option>
						<a-select-option :value="item.value" v-for="(item,index) in printArr" :key="index">
							{{item.label}}
						</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
			<div ref="kdprint" id="printMe" v-if="template_face" style="text-align: center;">
				<div v-html="template_face"></div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { printPdf,printOptions } from '@/util/print.js'
import print from 'print-js'
import { reactive, toRefs } from 'vue'
import tool from '@/util/tool'
import { $post } from '@/api/http'
import moment from "moment" 
export default{
	name:'com-print-face-order',
	props:{
		type:{ //订单类型 1商城订单 3 认养订单 4 土地订单
			type:Number,
			default:1
		},
		orderId:{				//订单id
			type:[Array,Object,Number],
		},
		btnType:{			//按钮类型
			type:String,
			default:"primary"
		},
		btnSize:{			//按钮大小
			type:String,
		},
		btnText:{			//按钮文字
			type:String,
			default:"电子面单"
		}
	},
	setup(props,context){
		const state = reactive({
			show:false,
			print_type:2,	//1浏览器打印  2 lodop插件打印
			form:{
				name:'',
				Weight:'',
				PayType:1,
				IsNotice:0,
				StartDate:'',
				EndDate:'',
				express_company:''
			},
			time:[],	//取件时间范围
			print_index:'-1', //打印机
			printArr:[],	//打印机列表
			template_face:'',
			expressData:[]
		})

		//点击电子面单按钮
		function showPrint(){
			if( typeof props.orderId == 'number'){
				if( !props.orderId ){
					tool.message("请选择要打印电子面单的订单","warning")
					return
				}
			}
			if( typeof props.orderId == 'object'){
				if( props.orderId.length == 0 ){
					tool.message("请选择要打印电子面单的订单","warning")
					return
				}
			}
			
			state.show = true
			state.printArr = printOptions()
			state.expressData = tool.getExpressCompany()
		}

		function print(){
			printJS({
				printable: 'printMe',// 标签元素id
				type: 'html',
				header: '这是标题',
			})
		}

		function printNow(){
			let { orderId ,type } = props, { form,time } = state
			if( !tool.returnMessage(form.express_company,"请选择快递公司")) return
			if( !tool.returnMessage(form.Weight,"请填写重量")) return
			if( time.length == 0 ){
				tool.message('请选择取件时间',"warning")
				return
			}

			let data = {
				order_ids:orderId,
				type:type,
				IsNotice:form.IsNotice,
				StartDate:moment(time[0]).format("YYYY-MM-DD HH:mm:ss"),
				EndDate:moment(time[1]).format("YYYY-MM-DD HH:mm:ss"),
				Weight:form.Weight,
				PayType:form.PayType,
				express_company:form.express_company
			}
			
			$post('expressByFaceOrder',data).then(res=>{
				if( res.data.success.length > 0 ){
					if( state.print_type == 2){
						let result = printPdf(res.data.success,t.print_index)
						close()
					}else{
						state.template_face = res.data.success[0].PrintTemplate
						console.log('本地打印');
						let timer = setTimeout(function(){
							clearTimeout(timer)
							printJS({
								printable: 'printMe',// 标签元素id
								type: 'html',
								header: '',
							})
							state.template_face = ''
							close()
						},1000)
					}
				}else{
					tool.message(res.data.error,"warning")
				}
			})

		}

		function close(){
			state.show = false
			context.emit('close',false)
		}

		return{
			...toRefs(state),
			showPrint,
			print,
			printNow,
			close
		}
	}
}
</script>

<style lang="scss">
	.kd-print-face-order{
		display: inline-block;
	}
</style>
