<template>
	<div class="order-refund">
		<a-button size="small" type="link" style="color:red" @click="refundOrder">同意退款</a-button>
		<a-button size="small" type="link" style="color:red" @click="show = true">拒绝退款</a-button>
		<a-modal v-model:visible="show" title="拒绝退款" width="500px" @ok="refuseRefund(order_id)" @cancel="show=false">
			<a-textarea v-model:value="reason" placeholder="拒绝退款理由" :rows="6" />
		</a-modal>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import goodsModel from '@/api/goods'
export default{
	name:'com-order-refund',
	props:{
		orderid:{
			type:[Number,String],
			default:0
		}
	},
	setup(props,context){
		const state = reactive({
			show:false,
			reason:''
		})

		const refuseRefund = ()=>goodsModel.orderRefuseRefund(props.orderid,state.reason,()=>{
			state.show = false
			context.emit("change",true)
		})

		const refundOrder = ()=>{
			goodsModel.handleOrder(props.orderid,'refund',"确认要对该订单进行退款操作吗?",()=>{
				state.show = false
				context.emit("change",true)
			})
		}

		return{
			...toRefs(state),
			refuseRefund,
			refundOrder
		}
	}
}
</script>

<style lang="scss">
</style>
