<template>
	<!-- 修改订单物流信息 -->
	<div class="delivery-update-express">
		<a-button type="link" @click="show= true">{{title}}</a-button>
		<a-modal title="修改物流信息" v-model:visible="show" @ok="submitUpdate" @cancel="show = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="快递公司">
					<a-select v-model:value="form.express_company">
						<a-select-option :value="item.express_company" v-for="(item,index) in expressData" :key="index">
							{{item.express_company}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="物流编号">
					<a-input v-model:value="form.express_no" placeholder="请输入物流编号"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import tool from '@/util/tool';
import goodsModel from '@/api/goods';
export default{
	name:"com-update-express",
	props:{
		type:{	//1=商城
			type:Number,
		},
		orderId:{
			type:[Number,String]
		},
		title:{
			type:String,
			default:"修改"
		}
	},
	setup(props,context){
		const state = reactive({
			show:false,
			form:{
				express_no:"",
				express_company:"",
			},
			expressData:[],		//快递公司
		})

		state.expressData = tool.getExpressCompany()
		
		function submitUpdate(){
			let form =JSON.parse(JSON.stringify(state.form))
			form.id = props.orderId
			form.type = props.type

			goodsModel.updateLogistics(form,()=>{
				state.show = false
				context.emit("success",true)
			})
		}

		return{
			...toRefs(state),
			submitUpdate
		}
	}
}
</script>

<style>
</style>
