<template>
	<div v-if="cashback_status_code !=-1">
		<span>返现状态：</span>
		<span>{{ cashback_status }}</span>
		<a-button type="link" v-if="cashback_status_code==1" @click="callbackOpera(2)">[立即暂停]</a-button>
		<a-button type="link" v-else @click="callbackOpera(1)">[立即开始]</a-button>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import cashbackModel from '@/api/addons/cashback'
export default{
	name:'com-cashback-status',
	props:{
		orderId:{
			type:[Number,String],
			default:0
		},
		orderType:{		//订单类型 1商城 2土地 3认养
			type:[Number,String],
			default:1
		},
	},
	setup(props){
		const state = reactive({
			cashback_status:'',
			cashback_status_code:'',
		})
		
		getCashback()

		function getCashback(){
			cashbackModel.getCashBackOrderStatus(props.orderType,props.orderId,res=>{
				if( res.errorCode == 0 ){
					if( JSON.stringify(res.data) != '[]' ){
						let type = res.data.type
						state.cashback_status_code = type
						state.cashback_status = type == 0 ?'未开始':(type == 1 ?'进行中':'已暂停')
					}else{
						state.cashback_status_code = 0
						state.cashback_status = "未开始"
					}
				}else{
					state.cashback_status_code = -1
					state.cashback_status = '无返现活动'
				}
			})
		}

		const callbackOpera = type=>cashbackModel.handleCashbackOrder(type,props.orderType,props.orderId,res=>{
			getCashback()
		})

		return{
			...toRefs(state),
			getCashback,
			callbackOpera
		}
	}
}
</script>

<style>
</style>

