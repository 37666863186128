<template>
	<div class="delivery-update-address">
		<a-button :type="btnType" @click="showUpadte">修改订单配送信息</a-button>
		<a-modal title="修改订单收货信息" v-model:visible="show" @ok="saveOrderAddress" @cancel="show = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="收货人姓名">
				    <a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="联系手机">
				    <a-input v-model:value="form.phone"></a-input>
				</a-form-item>
				<a-form-item label="收货地址">
					<kd-map :map-lnglat="[form.longitude,form.latitude]" @change="getAddress"></kd-map>
					<div>地址：{{province}}</div>
				</a-form-item>
				<a-form-item label="详细地址">
					<a-input v-model:value="form.address"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import goodsModel from '@/api/goods'
export default{
	name:'com-update-address',
	props:{
		orderid:{
			type:[Number,String],
			default:0
		},
		type:{	//订单类型
			type:Number,
			default:1
		},
		btnType:{
			type:String,
			default:'link'
		}
	},
	setup(props,context){
		const state = reactive({
			form:{
				name:'',
				phone:'',
				address:'',
				lat:'',
				lng:'',
			},
			province:'',
			show:false,
		})

		function showUpadte(){
			goodsModel.getOrderDetail(props.orderid,res=>{
				if(res.address_info ){
					let d = JSON.parse(res.address_info )
					state.form.name = d.name
					state.form.phone = d.phone
					state.form.address = d.address
					state.form.lat = d.lat
					state.form.lng = d.lng
				}else{
					state.form.name = res.name
					state.form.phone = res.phone
					state.form.address = res.address
				}
				state.show = true
			})
		}

		function saveOrderAddress(){
			let form = JSON.parse(JSON.stringify(state.form))
			form.id = props.orderid
			goodsModel.updateOrderAddress(form,()=>{
				state.show = false
				context.emit("change",true)
			})
		}

		function getAddress(e){
			state.form.address = e.address
			state.form.lat = e.latitude
			state.form.lng = e.longitude
			state.province = e.province+ e.city+e.district
		}
		return{
			...toRefs(state),
			showUpadte,
			getAddress,
			saveOrderAddress
		}
	}
}
</script>

<style>
</style>
