import {reactive, toRaw} from "vue";
import goodsModel from "@/api/goods";
import common from "@/api/common";
import set from "@/api/set";
import tool from "@/util/tool";

/**
 * 获取商品列表数据
 * @param state= {
 *     search:搜索参数
 *     isShop:是否为多商户
 *     is_purchase:是否为批发商品
 *     state: 多商户 =>商品审核状态 0待审核 1审核通过 2审核失败
 * }
 *
 */
export function useGoodsList(state){
    let goodsState = reactive({
        info:{
            list:[],
            page:1,
            limit:10,
            count:0
        }
    })
    getGoodsList(1,goodsState.info.limit)

    function getGoodsList(page,limit){
        let search = toRaw( state.search )
        if( state.isShop && state.audit ) search.audit = state.audit
        if( state.is_purchase ) search.is_purchase = state.is_purchase
        goodsModel.getGoodsList(page,limit,search,res=>goodsState.info = {limit,...res})
    }
    return { goodsState,getGoodsList }
}

/**
 * 商品状态改变（删除商品，切换商品上下架、推荐状态）
 * @param obj => goodsState商品列表数据 getGoodsList 刷新商品列表方法
 */
export function useChangeGoodsStatus(obj){

    //删除商品数据
    function deleteGoods(id){
        common.deleteDataList(id,8,"确认删除该商品信息吗？").then(()=>{
            let { page,limit } = obj.goodsState.info
            obj.getGoodsList(page,limit)
        })
    }

    //改变商品上下架状态 type 1上下架 2不/推荐
    function changeGoodsPutAway(id,value,type){
        goodsModel.changeGoodsStatus(id,value,type,()=>{
            let { page,limit } = obj.goodsState.info
            obj.getGoodsList(page,limit)
        })
    }

    return{ deleteGoods,changeGoodsPutAway }
}

//显示商品二维码
export function useGoodsQrcode(){
    let gqState = reactive({
        show:false,
        qrcode:""
    })
    function showQrcode(id){
        let path = `/pages/shop/goods_detail?goods_id=${id}`
        set.getQrcode(path,'wx_app').then(res=>{
            gqState.qrcode = res
            gqState.show = true
        })
    }

    return { gqState,showQrcode }
}

//获取商品分类
export function useGoodsCategory(obj){
    let cateState = reactive({
        list:[],
    })

    function getCategoryList(){
        goodsModel.getCategroyList(1,999,obj.search,res=> {
            cateState.list = res
            cateState.list.forEach(item=> item.children = item.child )
        })
    }

    return{ cateState ,getCategoryList }
}

//编辑、删除商品分类信息
export function useEditGoodsCategory(obj){
    let editCateState = reactive({
        show:false,
        form:null
    })

    function editType(row){
        editCateState.form = {
            id:row? row.id : 0 ,
            name:row? row.name : '',
            icon:row? row.icon : '',
            type:row? row.type : 1,
            rank:row? row.rank : 99,
            is_show:row ? row.is_show : 1,
            parent_id:row? row.parent_id : 0
        }
        editCateState.show = true
    }

    //保存类型
    function saveCate(){
        goodsModel.addOrEditCategory( toRaw( editCateState.form ),()=>{
            editCateState.show = false
            obj.getCategoryList()
        })
    }
    //删除分类
    function deleteCate(id){
        common.deleteDataList(id,7,"确认删除该商品分类吗?").then(()=> {
            obj.getCategoryList()
        })
    }
    return{ editCateState,editType,saveCate,deleteCate }
}

//获取商品服务信息
export function useService(){
    let serviceState = reactive({
        info:{
            list:[],
            page:1,
            limit:10,
            count:0
        }
    })
    function getService(page,limit){
        goodsModel.getGoodsServer(page,limit,{},res=>serviceState.info = {limit,...res})
    }
    return{ serviceState,getService }
}

//编辑、删除商品服务信息
export function useEditService(obj){
    let esState = reactive({
        show:false,
        form:{},
    })

    function showEditService(row){
        esState.form = {
            id:row.id ? row.id :0,
            name:row ? row.name : '',
            rank:row ? row.rank : 99,
            content:row ? row.content:''
        }
        esState.show = true
    }

    function saveService(){
        goodsModel.createOrEditGoodsServer(esState.form,()=>{
            obj.getService(obj.state.info.page,obj.state.info.limit)
            esState.show = false
        })
    }

    function deleteService(id){
        common.deleteDataList(id,9,"确认删除该商品服务吗?").then(()=>{
            obj.getService(obj.state.info.page,obj.state.info.limit)
        })
    }

    return{ esState,showEditService,saveService ,deleteService}
}

/**
 * 修改未支付订单价格
 * @param {object} state             订单列表数据
 * @param { function} getOrderList   获取订单列表数据
 * @returns
 */
export function useUpdateOrderPrice(state,getOrderList ){
    let uopState = reactive({
        show:false,
        order_id:0,
        current_price:0,   //当前订单价格
        manager_discount:0,
    })

    function showUpdatePrice(index){
        uopState.show = true
        let detail = state.info.list[index]
        uopState.order_id = state.info.list[index].id
        uopState.current_price =parseFloat(detail.total_price)  + parseFloat(detail.manager_discount)
    }

    function saveOrderPrice(){
        if( uopState.current_price <= uopState.manager_discount ){
            tool.message("优惠金额不能大于订单总金额","warning")
            return
        }

        goodsModel.updateOrderPrice( uopState.order_id,uopState.manager_discount,()=>{
            uopState.show = false
            if( typeof getOrderList  == 'function' ) getOrderList(state.info.page,state.info.limit )
        })
    }

    return { uopState,showUpdatePrice ,saveOrderPrice }
}


//导出订单
export function useExportOrder(state){
    let exportState = reactive({
        show:false,
        outCheckList:[],
    })

    function exportOrder(){
        goodsModel.exportOrder(state.search,'order',exportState.outCheckList)
    }

    function outCheckAll(){
        if( exportState.outCheckList.length !=14 ){
            exportState.outCheckList= [ 'order_number','total_price','order_status','name','address',
                'mobile','create_time','pay_method','pay_time','manager_remark',
                'manager_discount','send_time','confirm_time','goods_info'];
        }else{
            exportState.outCheckList = []
        }
    }

    return{ exportState,exportOrder,outCheckAll }
}

/**
 * 商城订单操作
 * @param state 状态信息
 * @param fn    页面刷新函数
 * @returns {}
 */
export function useChangeOrderStatus(state,fn){

    //刷新页面
    function refreshPage(){
        if( typeof fn != 'function') {
            return
        }
        if( state ){
            fn(state.info.page,state.info.limit )
            return;
        }
        fn()
    }

    function changeOrderStatus(scene ,id){
        switch (scene) {
            case "cancel":
                goodsModel.cancelOrder(id,()=>refreshPage() )
                break;
            case "intoRecycle":
            case "outRecycle":
                goodsModel.handleOrderSomeMethod(id,2,()=>refreshPage())
                break;
            case "delete":
                goodsModel.handleOrderSomeMethod(id,6,()=>refreshPage())
                break;
            case "confirm":
                goodsModel.handleOrderSomeMethod(id,3,()=>refreshPage())
                break;
            case "refund":
                goodsModel.refundOrder(id,()=>refreshPage())
                break;
            default:
                break;
        }

    }
    return { changeOrderStatus }
}

/**
 * 商品评论操作
 * @param goods_id
 * @returns
 */
export function useGoodsComment(goods_id){
    let gcStatus = reactive({
        list:[],
        page:1,
        limit:[],
        count:0
    })

    function getGoodsComment(page,limit ){
        goodsModel.getGoodsComment(page,limit,{goods_id},res=>{
            gcStatus.list = res.list
            gcStatus.page = res.page
            gcStatus.count = res.count
            gcStatus.limit = limit
        })
    }
    const deleteGoodsComment = id=>goodsModel.updateGoodsCommentStatus('delete',id,0,()=>{
        getGoodsComment(gcStatus.page,gcStatus.limit)
    })
    const updateGoodsCommentShow = (id,status)=>{
        goodsModel.updateGoodsCommentStatus('show',id,status,()=>{
            getGoodsComment(gcStatus.page,gcStatus.limit)
        })
    }
    return { gcStatus ,getGoodsComment,deleteGoodsComment,updateGoodsCommentShow }
}
