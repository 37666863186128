import {
	getLodop
} from "@/assets/js/LodopFuncs.js";
export const printPdf = (html,printIndex) => {
	let LODOP = getLodop();
	LODOP.PRINT_INIT("电子面单");
	LODOP.SET_PRINT_PAGESIZE(3, 1000,'', '')// 3代表纵向打印，宽度固定，高度按打印内容的高度自适应,纸张10*15
	for (var i = 0; i < html.length; i++) {
		creatOneRage(html[i].PrintTemplate)
	}
	LODOP.SET_PRINTER_INDEX(printIndex)  //指定打印机
	LODOP.PRINT();
}

const creatOneRage = function (html) {
	LODOP.ADD_PRINT_HTM("0", "0", '100%', '100%', html)
	LODOP.SET_PRINT_STYLEA(0, 'Stretch', 2)
	LODOP.NewPage()
}

export const printOptions = () => {
	LODOP = getLodop();
	let arr = []
	let iPrinterCount = LODOP.GET_PRINTER_COUNT();
	for (let i = 0; i < iPrinterCount; i++) {
		arr.push({
			label: LODOP.GET_PRINTER_NAME(i),
			value: i
		})
	}
	let defaultName = LODOP.GET_PRINTER_NAME('-1') //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
	arr.forEach(item => {
		if (item.label === defaultName) {
			item.value = '-1'
			item.label += '-默认打印机'
		}
	})
	
	return arr
}
